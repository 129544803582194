import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './components/Login';
import Services from './components/Services';
import Logs from './components/Logs';
import Navbar from './components/Navbar';
import Start from './components/Start';
import Register from './components/Register';
import Logout from './components/Logout';
import ServiceSettings from './components/ServiceSettings';
import ResetPassword from './components/ResetPassword';
import ForgotPassword from './components/ForgotPassword';
import VerifyEmail from './components/VerifyEmail';
import Changelog from './components/Changelog';

function App() {
  return (
    <Router>
      <div>
        {/* Navbar */}
        <Navbar />
        {/* Routes */}
        
        <Routes>
          <Route path="/" element={<Start />} />
          <Route path="/login" element={<Login />} />
          <Route path="/services" element={<Services />} />
          <Route path="/logs/:serviceId" element={<Logs />} />
          <Route path="/register" element={<Register />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="*" element={<h1>404 - Nie znaleziono strony</h1>} />
          <Route path='/settings/:serviceId' element={<ServiceSettings />} />
          <Route path='/reset-password/:token' element={<ResetPassword />} />
          <Route path='/forgot-password' element={<ForgotPassword />} />
          <Route path='/verify-email/:token' element={<VerifyEmail />} />
          <Route path='/changelog' element={<Changelog />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
