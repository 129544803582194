import React from 'react';
// import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client'
import App from './App';
import './assets/styles/global_styles.css';
import { AuthProvider } from './components/AuthContext';
const container = document.getElementById("root");

const root = createRoot(container);
// root.render(<App />);
root.render(
  <React.StrictMode>
    <AuthProvider>
        <App />
    </AuthProvider>
  </React.StrictMode>
);
