import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import API from '../utils/api';
import '../assets/styles/ServiceSettings.css';

const ServiceAdditions = () => {
  const { serviceId } = useParams();
  const [serviceData, setServiceData] = useState(null);
  const [additions, setAdditions] = useState({});
  const [settingsUpdates, setSettingsUpdates] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [updateInfo, setUpdateInfo] = useState({ message: '', type: '' });

  useEffect(() => {
    const fetchServiceData = async () => {
      try {
        const response = await API.get(`/api/services/${serviceId}`,  { headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        } });

        setServiceData(response.data);
        console.log(response.data);
        try{
          let parsed_settings = response.data.additions;
          parsed_settings = JSON.parse(parsed_settings);
          if(typeof parsed_settings === "string"){
            parsed_settings = JSON.parse(parsed_settings);
          }
          console.log(parsed_settings);
          setAdditions(parsed_settings);
        }catch{
            setAdditions({});
        }
      } catch (err) {

        setError('Failed to fetch service data. Please try again later.');
        console.error('Error fetching service data:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchServiceData();
  }, [serviceId]);

  const handleSettingChange = (key, value) => {
    setSettingsUpdates((prevSettings) => ({
      ...prevSettings,
      [key]: value,
    }));
  };

  const showUpdateInfo = (message, type) => {
    setUpdateInfo({ message, type });
    setTimeout(() => {
      setUpdateInfo({ message: '', type: '' });
    }, 5000);
  };

  const renderSettingInput = (key, value) => {
    if (typeof value === 'boolean') {
      return (
        <select
          value={value.toString()}
          onChange={(e) => handleSettingChange(key, e.target.value === 'true')}
        >
          <option value="true">Włączone</option>
          <option value="false">Wyłączone</option>
        </select>
      );
    }
    // else if (typeof value === 'object'){
    else if (value.constructor === Array){
      const objectFromSettings = value; 
      console.log(objectFromSettings);
      return (
        <>
        <label>Priorytet 1</label>
      <select onChange={(e) => handleSettingChange("Priorytet 1", e.target.value)}>
        {objectFromSettings.map((item, index) => {
          return (<option key={index +1} value={Object.values(item)}>{Object.keys(item)}: {Object.values(item)}</option>)
        })}
      </select> 
      <label>Priorytet 2</label>
      <select onChange={(e) => handleSettingChange("Priorytet 2", e.target.value)}>
        {objectFromSettings.map((item, index) => {
          return (<option key={index +1} value={Object.values(item)}>{Object.keys(item)}: {Object.values(item)}</option>)
        })}
      </select> 
      <label>Priorytet 3</label>
      <select onChange={(e) => handleSettingChange("Priorytet 3", e.target.value)}>
        {objectFromSettings.map((item, index) => {
          return (<option key={index +1} value={Object.values(item)}>{Object.keys(item)}: {Object.values(item)}</option>)
        })}
      </select> 
      </>
      )
    }
    else if(typeof value === 'string') {
      return (
        <p>{value}</p>
      )
    }
  };

  const saveSettings = async (e) => {
    e.preventDefault();
    try {
      //const updatedSettings = JSON.stringify(settings);
      const updatedSettings = Object.assign(additions, settingsUpdates);
      console.log("UPDATED SETTINGS: ", updatedSettings);
      const response = await API.patch(`/api/services/${serviceId}/additions`, { additions: updatedSettings }, { headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      } });
      if (response.status === 200) {
        showUpdateInfo('Zapisano ustawienia', 'true');
      } else {
        showUpdateInfo('Wystąpił błąd podczas zapisywania ustawień - skontaktuj się z administratorem', 'false');
      }
    } catch (err) {
      showUpdateInfo('Wystąpił błąd podczas zapisywania ustawień - skontaktuj się z administratorem', 'false');
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className='service-settings'>
      {serviceData && (
        <div>

          <h2>Dodatkowe</h2>
          <form>
          <table className="settings-table-additional">
            <tbody>
            {
              
              (additions && Object.keys(additions).length > 0) ?
                Object.entries(additions).map(([key, value]) => (
                  <tr key={key}>
                    <td>{key}</td>
                    <td>{renderSettingInput(key, value)}</td>
                  </tr>
                )) :
                <tr>
                  <td colSpan="2">Brak ustawień dodatkowych</td>
                </tr>
            }
            </tbody>
            
          </table>
          {additions && Object.keys(additions).length > 0 && (
             <>
                <button className="save-button" type="submit" onClick={saveSettings}>Zapisz dodatkowe ustawienia</button>
                <div className={`settings-updated-info ${updateInfo.type}`}>
                  {updateInfo.message}
                </div>
              </>
            )
          }
          </form>
        </div>
      )}
    </div>
  );
};

export default ServiceAdditions;
