import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import API from '../utils/api';
import * as DOMPurify from 'dompurify';
import '../assets/styles/Logs.css';

const Logs = () => {
  const [conversations, setConversations] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [dateFilter, setDateFilter] = useState('');
  const [channelFilter, setChannelFilter] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [showTodayDetails, setShowTodayDetails] = useState(false);
  const [showWeekDetails, setShowWeekDetails] = useState(false);
  const [showMonthDetails, setShowMonthDetails] = useState(false); 
  const [todaySubStatuses, setTodaySubStatuses] = useState({});
  const [weekSubStatuses, setWeekSubStatuses] = useState({});
  const [monthSubStatuses, setMonthSubStatuses] = useState({});
  const [currentPage, setCurrentPage] = useState(1); // For pagination
  const [logsPerPage, setLogsPerPage] = useState(30); // For pagination
  const { serviceId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchLogs = async () => {
      try {
        const logsRes = await API.get(`/api/services/${serviceId}/logs`, {
          headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` },
        });

        if (logsRes.status === 401) {
          setError('Nie jesteś zalogowany. Zaloguj się i spróbuj ponownie.');
          navigate('/login');
          return;
        }

        // Group logs by conversation_id
        const groupedLogs = logsRes.data.reduce((acc, log) => {
          if (!acc[log.conversation_id]) {
            acc[log.conversation_id] = { logs: [], isOpen: false };
          }
          acc[log.conversation_id].logs.push(log);
          return acc;
        }, {});
        setConversations(groupedLogs);
      } catch (err) {
        setError('Nie udało się załadować logów. Spróbuj ponownie później.');
        console.error('Error fetching logs:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchLogs();
  }, [serviceId, navigate]);

  const toggleConversation = (conversationId) => {
    setConversations((prevConversations) => ({
      ...prevConversations,
      [conversationId]: {
        ...prevConversations[conversationId],
        isOpen: !prevConversations[conversationId].isOpen,
      },
    }));
  };

  const calculateSummaries = (conversations) => {
    // ... existing logic for todayStart, weekStart, monthStart

    let todaySummary = { total: 0, statuses: {} };
    let weekSummary = { total: 0, statuses: {} };
    let monthSummary = { total: 0, statuses: {} };
    let todayChannels = {};
    let weekChannels = {};
    let monthChannels = {};

    Object.values(conversations).forEach(conversation => {
      const firstLog = conversation.logs[0];

      logging: if (firstLog) {
        const logDate = new Date(firstLog.message_timestamp);
        let localStatus = firstLog.status;

        // Extract substatus if present
        let substatus = null;
        if (localStatus && localStatus.includes('(')) {
          [localStatus, substatus] = localStatus.split(' (');
          substatus = substatus.slice(0, -1); // Remove the closing parenthesis
        }

        if (!localStatus && firstLog.channel === 'voice'){
          localStatus = 'Rozłączono natychmiast (telefon)';
        } else if (!localStatus){
          break logging;
        }

        const todayStart = new Date();
        todayStart.setHours(0, 0, 0, 0);
        const weekStart = new Date();
        weekStart.setDate(weekStart.getDate() - weekStart.getDay());
        weekStart.setHours(0, 0, 0, 0);
        const monthStart = new Date();
        monthStart.setDate(1);
        monthStart.setHours(0, 0, 0, 0);

        // Function to update summary
        const updateSummary = (summary, channels, status, substatus) => {
          summary.total++;
          if (!summary.statuses[status]) {
            summary.statuses[status] = { total: 0, substatuses: {} };
          }
          summary.statuses[status].total++;
          if (substatus) {
            summary.statuses[status].substatuses[substatus] = 
              (summary.statuses[status].substatuses[substatus] || 0) + 1;
          }

          if (!channels[firstLog.channel]) {
            channels[firstLog.channel] = 0;
          }
          channels[firstLog.channel]++;
        };
      
        if (logDate >= todayStart) {
          updateSummary(todaySummary, todayChannels, localStatus, substatus);
        }

        if (logDate >= weekStart) {
          updateSummary(weekSummary, weekChannels, localStatus, substatus);
        }
        if (logDate >= monthStart) {
          updateSummary(monthSummary, monthChannels, localStatus, substatus);
        }
      }
    });

    return { todaySummary, weekSummary, monthSummary, todayChannels, weekChannels, monthChannels};
  };

  const { todaySummary, weekSummary, monthSummary, todayChannels, weekChannels, monthChannels } = calculateSummaries(conversations);

  const [tempStatuses, setTempStatuses] = useState({});

  // Function to handle input change
  const handleStatusChange = (conversationId, newStatus) => {
    setTempStatuses(prevStatuses => ({
      ...prevStatuses,
      [conversationId]: newStatus
    }));
  };

  const lightUpRow = (conversationId) => {
    //get row with conversation_id_row = conversationId
    const row = document.querySelector(`[conversation_id_row="${conversationId}"]`);
    row.classList.add('highlighted');
    setTimeout(() => {
      row.classList.remove('highlighted');
    }, 2000);
  };

  const toggleSubStatus = (summaryType, status) => {
    if (summaryType === 'today') {
      setTodaySubStatuses(prev => ({
        ...prev,
        [status]: !prev[status]
      }));
    } else if (summaryType === 'week') {
      setWeekSubStatuses(prev => ({
        ...prev,
        [status]: !prev[status]
      }));
    } else if (summaryType === 'month') {
      setMonthSubStatuses(prev => ({
        ...prev,
        [status]: !prev[status]
      }));
    }
  };
  
  const applyFilters = (conversations) => {
    let tmp_conv = Object.entries(conversations).filter(([conversationId, conversationData]) => {
      const firstLog = conversationData.logs[0];
      return (!dateFilter || (firstLog.message_timestamp && firstLog.message_timestamp.includes(dateFilter))) &&
              (!channelFilter || (firstLog.channel && firstLog.channel.includes(channelFilter))) &&
              (!statusFilter || (firstLog.status && firstLog.status.includes(statusFilter)));
    });

    //remove conversations with empty status
    tmp_conv = tmp_conv.filter(([conversationId, conversationData]) => {
      const firstLog = conversationData.logs[0];
      return firstLog.status;
    });

    // Pagination
    const indexOfLastLog = currentPage * logsPerPage;
    const indexOfFirstLog = indexOfLastLog - logsPerPage;
    tmp_conv = tmp_conv.reverse().slice(indexOfFirstLog, indexOfLastLog);

    return tmp_conv;
  };
  
  const setStatus = async (serviceId, conversationId, status) => {
    try {
      const status_result = await API.patch(`/api/logs/update/status`, {
        serviceId,
        conversation_id: conversationId,
        status
      }, {
        headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` },
      });
      if (status_result.status === 200){
        lightUpRow(conversationId);
      }
    } catch (err) {
      console.error('Error setting status:', err);
    }
  };

  // Helper function to format time
  const formatTime = (timestamp) => {
    const date = new Date(timestamp);

    // Get local time components
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');

    // Format the time as HH:MM:SS
    const time = `${hours}:${minutes}:${seconds}`;

    return time;
  };

  const formatDateTime = (timestamp) => {
    const date = timestamp.split('T')[0];

    const date_stamp = new Date(timestamp);

    // Get local time components
    var hours = date_stamp.getHours().toString().padStart(2, '0');
    const minutes = date_stamp.getMinutes().toString().padStart(2, '0');
    const seconds = date_stamp.getSeconds().toString().padStart(2, '0');

    // Format the time as HH:MM:SS
    const time = `${hours}:${minutes}:${seconds}`;

    return `${date} ${time}`;
  };

  if (loading) {
    return <div className="loading-info">Ładowanie logów...</div>;
  }

  if (error) {
    return <div className="error-info">{error}</div>;
  }

  return (
    <div className="logs-container">
      <h1>Zapisy rozmów</h1> 
      <br />
      <h2>Podsumowania (kliknij, by rozwinąć)</h2> 
      <div className="summary-section">
        <h3 className="summary-header" onClick={() => setShowTodayDetails(!showTodayDetails)}>
          Dzisiaj rozmów: {todaySummary.total - (todaySummary.statuses['Rozłączono natychmiast (telefon)']?.total ?? 0)}
          <div className="channel-summaries-container">
            {Object.entries(todayChannels).map(([channel, count]) => (
              <div className='channel-summary' key={channel}>{channel}: {count}</div>
            ))}
          </div>
        </h3>
        {showTodayDetails && (
          <div className="summary-details">
            {Object.entries(todaySummary.statuses).map(([status, data]) => (
              <div key={status}>
                <b onClick={() => toggleSubStatus('today', status)}>{status}
                {Object.keys(data.substatuses).length > 0 && (
                  todaySubStatuses[status] ? ' ▲' : ' ▼'
                )}
                </b> {data.total}
                {todaySubStatuses[status] && (
                  <div className="sub-status">
                    {Object.entries(data.substatuses).map(([substatus, count]) => (
                      <div key={substatus}>{substatus}: {count}</div>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>

      <div className="summary-section">
        <h3 className="summary-header" onClick={() => setShowWeekDetails(!showWeekDetails)}>
          Rozmów w tym tygodniu: {weekSummary.total - (weekSummary.statuses['Rozłączono natychmiast (telefon)']?.total ?? 0)}
          <div className="channel-summaries-container">
            {Object.entries(weekChannels).map(([channel, count]) => (
              <div className='channel-summary' key={channel}>{channel}: {count}</div>
            ))}
          </div>
        </h3>
        {showWeekDetails && (
          <div className="summary-details">
            {Object.entries(weekSummary.statuses).map(([status, data]) => (
              <div key={status}>
                <b onClick={() => toggleSubStatus('week', status)}>{status}
                {Object.keys(data.substatuses).length > 0 && (
                  weekSubStatuses[status] ? ' ▲' : ' ▼'
                )}
          </b> {data.total}
                {weekSubStatuses[status] && (
                  <div className="sub-status">
                    {Object.entries(data.substatuses).map(([substatus, count]) => (
                      <div key={substatus}>{substatus}: {count}</div>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>

      <div className="summary-section">
        <h3 className="summary-header" onClick={() => setShowMonthDetails(!showMonthDetails)}>
          Rozmów w tym miesiącu: {monthSummary.total - (monthSummary.statuses['Rozłączono natychmiast (telefon)']?.total ?? 0)}
          <div className="channel-summaries-container">  {/* Wrap channel summaries */}
            {Object.entries(monthChannels).map(([channel, count]) => (
              <div className='channel-summary' key={channel}>{channel}: {count}</div>
            ))}
          </div>
        </h3>
        {showMonthDetails && (
          <div className="summary-details">
            {Object.entries(monthSummary.statuses).map(([status, data]) => (
              <div key={status}>
                <b onClick={() => toggleSubStatus('month', status)}>
                  {status}
                  {Object.keys(data.substatuses).length > 0 && (
                    monthSubStatuses[status] ? ' ▲' : ' ▼'
                  )}
                  </b> {data.total}
                {monthSubStatuses[status] && (
                  <div className="sub-status">
                    {Object.entries(data.substatuses).map(([substatus, count]) => (
                      <div key={substatus}>{substatus}: {count}</div>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
      <div className="filters">
        <input type="date" value={dateFilter} onChange={(e) => setDateFilter(e.target.value)} />
        <input type="text" placeholder="Kanał" value={channelFilter} onChange={(e) => setChannelFilter(e.target.value)} />
        <input type="text" placeholder="Status" value={statusFilter} onChange={(e) => setStatusFilter(e.target.value)} />
      </div>
      <table>
        <thead>
          <tr>
            <th></th>
            <th>Data i godzina</th>
            <th>Kanał rozmowy</th>
            <th>Identyfikator rozmowy</th>
            <th>Status</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {applyFilters(conversations).map(([conversationId, conversationData]) => {
            // Check if the status is empty or not
            if (!conversationData.logs[0].status) {
              return null; // Skip rendering this row
            }

            return (
              <React.Fragment key={conversationId}>
                <tr className={`conversation-summary`} conversation_id_row={`${conversationData.logs[0].id}`} >
                  <td className="conversation-expand" onClick={() => toggleConversation(conversationId)}>
                    {conversationData.isOpen ? '▲' : '▼'}
                  </td>
                  <td>{formatDateTime(conversationData.logs[0].message_timestamp)}</td>
                  <td>{conversationData.logs[0].channel}</td>
                  <td>{conversationId}</td>
                  <td className="conversation-status-input">
                    <input type="text" value={tempStatuses[conversationId] ?? conversationData.logs[0].status} onChange={(e) => handleStatusChange(conversationId, e.target.value)} />
                  </td>
                  <td><button className='conversation-status-save' onClick={() => setStatus(serviceId, conversationData.logs[0].id, tempStatuses[conversationId] ?? conversationData.logs[0].status)}>Zapisz</button></td>
                </tr>
                {conversationData.isOpen && (
                  <tr className="conversation-details">
                    <td className="conversation-sublog" colSpan="5">
                      {conversationData.logs.map((log, index) => {
                        const clearCode = DOMPurify.sanitize(log.message);
                        return (
                        <div key={index} className="log-entry">
                          <div>{formatTime(log.message_timestamp)}</div>
                          <div className={`log-type ${log.type === 'bot' ? 'darkblue' : 'lightblue'}`}>
                            {log.type === 'bot' ? 'Bot' : 'Użytkownik'}
                          </div>
                          <div dangerouslySetInnerHTML={{__html: clearCode}}></div>
                        </div>
                      )})}
                    </td>
                  </tr>
                )}
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
      <div className="pagination">
        <button onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1}>Poprzednia strona</button>
        <span style={{margin: '0 10px'}}>Strona {currentPage}</span>
        <button onClick={() => setCurrentPage(currentPage + 1)} disabled={applyFilters(conversations).length < logsPerPage}>Następna strona</button>
        <select value={logsPerPage} onChange={(e) => setLogsPerPage(e.target.value)}>
          <option value="10">10</option>
          <option value="30" selected>30</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </select>
      </div>
    </div> 
  );
};

export default Logs;