import React, { useState, useContext } from 'react';
import API from '../utils/api';
import { AuthContext } from './AuthContext';
import '../assets/styles/Login.css';
import { useNavigate, Link } from 'react-router-dom';

const Login = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const [error, setError] = useState('');
  const { setIsAuthenticated } = useContext(AuthContext);

  // const existing_token = localStorage.getItem('token');

  // const verifyExistingToken = async () => {
  //   try {
  //     const res = await API.get('/api/user/verifyToken', {
  //       headers: { Authorization: `Bearer ${existing_token}` },
  //     });
  //     if(res.data.valid){
  //       setIsAuthenticated(true);
  //       navigate('/services');
  //     }
  //   }
  //   catch (err) {
  //     console.log(err);
  //     localStorage.removeItem('token');
  //     setIsAuthenticated(false);
  //   }
  // }

  // if (existing_token) {
  //   verifyExistingToken();
  // }

  const { email, password } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await API.post('/api/user/login', { email, password });
      if(res.status !== 200){
        return setError(res.data);
      }
      const data = res.data;
      localStorage.setItem('token', data.token);
      setIsAuthenticated(true);
      navigate('/services')
    } catch (err) {
      console.log(err);
      setError("Wystąpił błąd podczas logowania. Skontaktuj się z administratorem.")
    }
  };

  return (
    <div className="login-container">
      <h1>Portal PROA Technology</h1>
      <form onSubmit={onSubmit}>
        <div>
          <input
            type="text"
            placeholder="Adres e-mail"
            name="email"
            value={email}
            onChange={onChange}
            required
          />
        </div>
        <div>
          <input
            type="password"
            placeholder="Hasło"
            name="password"
            value={password}
            onChange={onChange}
            required
          />
        </div>
        {error && <div className="error">{error}</div>}
        <button type="submit">Zaloguj się</button>
        <p className="forgot-password">
          <Link to="/forgot-password">Zapomniałeś hasła?</Link>
        </p>
      </form>
    </div>
  );
};

export default Login;
