import React from 'react';
import '../assets/styles/Changelog.css';

const changelogData = [
  {
    date: '2024-02-15',
    description: 'Rozszerzono podsumowania ilości rozmów o podział na kanały komunikacji.'
  },
  {
    date: '2023-12-23',
    description: 'Podzielono rozmowy na strony. Dodano możliwość wyboru ilości rozmów na stronę.'
  },
  {
    date: '2023-12-22',
    description: 'Dodano rozwijanie statusów dodatkowych w podsumowaniach rozmów.'
  },
  {
    date: '2023-12-01',
    description: 'Usunięto identyfikatory z historii rozmów.'
  },
  {
    date: '2023-11-28',
    description: 'W podsumowaniach rozmów dodano informację o połączeniach telefonicznych rozłączonych natychmiastowo. Nie będą one wliczać się do sumy rozmów.'
  },
  {
    date: '2023-11-17',
    description: 'Dodano podsumowania do strony historii rozmów.'
  },
];

const Changelog = () => {
  return (
    <div className="changelog-container">
      <h1>Ostatnie aktualizacje portalu</h1>
      <ul>
        {changelogData.map((entry, index) => (
          <li key={index}>
            <strong>{entry.date}</strong> - {entry.description}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Changelog;
