import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import API from '../utils/api';
import '../assets/styles/ServiceSettings.css';
import ServiceAdditions from './ServiceAdditions';

const ServiceSettings = () => {
  const { serviceId } = useParams();
  const [serviceData, setServiceData] = useState(null);
  const [settings, setSettings] = useState({});
  const [settingsUpdates, setSettingsUpdates] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [updateInfo, setUpdateInfo] = useState({ message: '', type: '' });

  useEffect(() => {
    const fetchServiceData = async () => {
      try {
        const response = await API.get(`/api/services/${serviceId}`,  { headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        } });

        setServiceData(response.data);
        try{
          let parsed_settings = response.data.settings;
          parsed_settings = JSON.parse(parsed_settings);
          if(typeof parsed_settings === "string"){
            parsed_settings = JSON.parse(parsed_settings);
          }
          setSettings(parsed_settings);
        }catch{
          setSettings({});
        }
      } catch (err) {

        setError('Failed to fetch service data. Please try again later.');
        console.error('Error fetching service data:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchServiceData();
  }, [serviceId]);

  const handleSettingChange = (key, value) => {
    setSettingsUpdates((prevSettings) => ({
      ...prevSettings,
      [key]: value,
    }));
  };

  const showUpdateInfo = (message, type) => {
    setUpdateInfo({ message, type });
    setTimeout(() => {
      setUpdateInfo({ message: '', type: '' });
    }, 5000);
  };

  const renderSettingInput = (key, value) => {
    if (typeof value === 'boolean') {
      return (
        <select
          value={value.toString()}
          onChange={(e) => handleSettingChange(key, e.target.value === 'true')}
        >
          <option value="true">Włączone</option>
          <option value="false">Wyłączone</option>
        </select>
      );
    }
    // else if (typeof value === 'object'){
    else if (value.constructor === Array){
      const objectFromSettings = value; 
      return (
      <select onChange={(e) => handleSettingChange("Aktywny link", e.target.value)}>
        {objectFromSettings.map((item, index) => {
          return (<option key={index +1} value={item}>{Object.keys(item)}: {Object.values(item)}</option>)
        })}
        {/* {Object.keys(objectFromSettings).map((item, index) => {
          return <option key={index +1} value={objectFromSettings[item]}>{index+1}. {item} - {objectFromSettings[item]}</option>
        })} */}
      </select> 
      )
    }
    else if(typeof value === 'string') {
      return (
        <p>{value}</p>
      )
    }
    else if(typeof value === 'string') {
      return (
        <p>{value}</p>
      )
    }
  };

  const saveSettings = async (e) => {
    e.preventDefault();
    try {
      //const updatedSettings = JSON.stringify(settings);
      const updatedSettings = Object.assign(settings, settingsUpdates);
      const response = await API.patch(`/api/services/${serviceId}/settings`, { settings: updatedSettings }, { headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      } });
      if (response.status === 200) {
        showUpdateInfo('Zapisano ustawienia', 'true');
      } else {
        showUpdateInfo('Wystąpił błąd podczas zapisywania ustawień - skontaktuj się z administratorem', 'false');
      }
    } catch (err) {
      showUpdateInfo('Wystąpił błąd podczas zapisywania ustawień - skontaktuj się z administratorem', 'false');
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <>
    <div className='service-settings'>
      <h1>Informacje o usłudze: {serviceData.name}</h1>
      {serviceData && (
        <div>
          <table className='settings-table'>
            <tbody>
              <tr>
                <td>Nazwa usługi</td>
                <td>{serviceData.name}</td>
              </tr>
              <tr>
                <td>Opis</td>
                <td>{serviceData.description}</td>
              </tr>
              <tr>
                <td>Status</td>
                <td className={`service-button ${serviceData.status ? 'service-on' : 'service-off'}`}>{serviceData.status ? 'Włączony' : 'Wyłączony'}</td>
              </tr>
              <tr>
                <td>Zarejestrowano</td>
                <td>{serviceData.created_at}</td>
              </tr>
            </tbody>
          </table>

          <h2>Ustawienia</h2>
          <form>
          <table className="settings-table-additional">
            <tbody>
            {
              
              (settings && Object.keys(settings).length > 0) ?
                Object.entries(settings).map(([key, value]) => (
                  <tr key={key}>
                    <td>{key}</td>
                    <td>{renderSettingInput(key, value)}</td>
                  </tr>
                )) :
                <tr>
                  <td colSpan="2">Brak ustawień dodatkowych</td>
                </tr>
            }
            </tbody>
            
          </table>
          {settings && Object.keys(settings).length > 0 && (
             <>
                <button className="save-button" type="submit" onClick={saveSettings}>Zapisz ustawienia</button>
                <div className={`settings-updated-info ${updateInfo.type}`}>
                  {updateInfo.message}
                </div>
              </>
            )
          }
          </form>
        </div>
      )}
    </div>
    <ServiceAdditions />
    </>
  );
};

export default ServiceSettings;
