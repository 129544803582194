import React, { useState } from 'react';
import API from '../utils/api';
import '../assets/styles/Register.css';
// import { useNavigate } from 'react-router-dom';

const Register = () => {
  // const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    confirmPassword: '',
  });
  const [error, setError] = useState('');
  // const [backupCodes, setBackupCodes] = useState([]);
  const [registrationSuccess, setRegistrationSuccess] = useState(false);

  const { email, password, confirmPassword } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setError('Hasła się nie zgadzają');
      return;
    }
    try {
      const res = await API.post('/api/user/register', { email, password });
      if (res.status !== 200) {
        setError("Coś poszło nie tak. Spróbuj ponownie.");
        return;
      }
      // Assuming the backend sends back backup codes on successful registration
      // setBackupCodes(res.data.backupCodes);
      setRegistrationSuccess(true);
      // Clear form data
      setFormData({
        email: '',
        password: '',
        confirmPassword: '',
      });
    } catch (err) {
      setError("Twoje konto zostało automatycznie aktywowane. Przejdź do logowania!"); // Handle errors returned from the backend
    }
  };

  return (
    <div className="register-container">
      {registrationSuccess ? (
        <div>
          <h1>Rejestracja zakończona sukcesem!</h1>
          <h2>Sprawdź swój adres e-mail. Powinieneś otrzymać wiadomość z linkiem aktywacyjnym.<br /> Kliknij go, by aktywować swoje konto!</h2>
        </div>
      ) : (
        <div>
          <h1>Utwórz konto</h1>
          <form onSubmit={onSubmit}>
            <div>
              <input
                type="text"
                placeholder="Adres e-mail"
                name="email"
                value={email}
                onChange={onChange}
                required
              />
            </div>
            <div>
              <input
                type="password"
                placeholder="Hasło"
                name="password"
                value={password}
                onChange={onChange}
                required
              />
            </div>
            <div>
              <input
                type="password"
                placeholder="Potwierdź hasło"
                name="confirmPassword"
                value={confirmPassword}
                onChange={onChange}
                required
              />
            </div>
            {error && <div className="error">{error}</div>}
            <button type="submit">Zarejestruj</button>
          </form>
        </div>
      )}
    </div>
  );
};

export default Register;
