import React, { useState, useEffect, useMemo } from 'react';
import API from '../utils/api';
import { useNavigate } from 'react-router-dom';
import '../assets/styles/Services.css';

const Services = () => {
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const token = useMemo(() => localStorage.getItem('token'), []);

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const res = await API.get('/api/services/', { headers: {
          'Authorization': `Bearer ${token}`
        } });
        setServices(res.data);
      } catch (err) {
        if (err.response.status === 401) {
          setError("Nie jesteś zalogowany. Zaloguj się, aby kontynuować.");
          navigate('/login');
        };
        // Handle error, e.g., redirect to login if unauthorized
      } finally {
        setLoading(false);
      }
    };

    fetchServices();
  }, [navigate, token]);

  const goToLogs = (serviceId) => {
    navigate(`/logs/${serviceId}`);
  };

  const goToSettings = (serviceId) => {
    navigate(`/settings/${serviceId}`);
  };

  if (loading) {
    return <div className='loading-info'>Ładowanie serwisów - proszę chwilę zaczekać...</div>;
  }

  if (error) {
    return <div className="error-info">{error}</div>;
  }

  return (
    <div className="services-container">
      <h1>Twoje usługi</h1>
      <table>
        <thead>
          <tr>
            <th>Nazwa usługi</th>
            <th>Opis</th>
            <th>Status</th>
            <th>Historia działań</th>
            <th>Ustawienia</th>
          </tr>
        </thead>
        <tbody>
          {services.map(service => (
            <tr key={service.id}>
              <td>{service.shown_name ? service.shown_name : service.name}</td>
              <td>{service.description}</td>
              <td className={`service-button ${service.status ? 'service-on' : 'service-off'}`}>{service.status ? 'Włączony' : 'Wyłączony'}</td>
              <td className='service-button'>
                <button onClick={() => goToLogs(service.id)}>Historia</button>
              </td>
              <td className='service-button'>
                <button onClick={() => goToSettings(service.id)}>Ustawienia</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Services;
