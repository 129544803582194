import React, { useState } from 'react';
import API from '../utils/api';
import '../assets/styles/ForgotPassword.css';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      // Call the backend API to initiate the password reset process
      await API.post('/api/user/forgot-password', { email });
      setMessage('Instrukcje do zresetowania hasła zostały wysłane na Twój adres e-mail.');
    } catch (err) {
      console.log(err);
      setMessage('Błąd podczas resetowania hasła. Skontaktuj się z administratorem.');
    }
  };

  return (
    <div className="forgot-password-container">
      <h1>Zresetuj hasło</h1>
      <form onSubmit={onSubmit}>
        <input
          type="email"
          placeholder="Twój adres e-mail"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <button type="submit">Wyślij prośbę o reset hasła</button>
      </form>
      {message && <div className="message">{message}</div>}
    </div>
  );
};

export default ForgotPassword;
