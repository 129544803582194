import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import API from '../utils/api';

const VerifyEmail = () => {
    const { token } = useParams();
    const [message, setMessage] = useState('');
    const [status , setStatus] = useState(false);

    useEffect(() => {
        const verifyEmail = async () => {
            try {
                const response = await API.post(`/api/user/verify-token`, { token });
                if(response.status !== 200){
                    setMessage(response.data);
                    setStatus(false);
                } else {
                    setMessage(response.data);
                    setStatus(true);
                }
            } catch (error) {
                setMessage('Nie udało się zweryfikować adresu email. Skontaktuj się z administratorem.');
            }
        };
        verifyEmail();
    }, [token]);

    return (
        <div>
            {status ? (status === true ? (
                <div>
                    <h1>Adres email zweryfikowany poprawnie, możesz się zalogować!</h1>
                    <button onClick={() => window.location.href = '/login'}>Przejdź do logowania</button>
                </div>
            ) : (
                <div>
                    <h1>Wystąpił błąd!</h1>
                    <p>{message}</p>
                </div>
            )) : (
                <div>
                    <h1>Weryfikowanie adresu email...</h1>
                </div>
            )}
        </div>
    );
};

export default VerifyEmail;
