import React, { useState } from 'react';
import API from '../utils/api';
import { useParams, useNavigate } from 'react-router-dom';
import '../assets/styles/ResetPassword.css';


const ResetPassword = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();
  const { token } = useParams();

  const onSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setMessage('Passwords do not match.');
      return;
    }
    try {
      await API.post(`/api/user/reset-password/${token}`, { password });
      setSuccess(true);
      setMessage('Hasło zostało zresetowane. Możesz się zalogować.');
    } catch (err) {
      console.log(err);
      setMessage('Nie udało się zresetować hasła. Skontaktuj się z administratorem.');
    }
  };

  return (
    <div className="reset-password-container">
      <h1>Zresetuj swoje hasło</h1>
      <form onSubmit={onSubmit}>
        <input
          type="password"
          placeholder="Nowe hasło"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="Potwierdź nowe hasło"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          required
        />
        <button type="submit">Zresetuj hasło</button>
      </form>
      {message && <div className="message">{message}</div>}<br />
      {success && <button onClick={() => navigate('/login')}>Przejdź do logowania</button>}
    </div>
  );
};

export default ResetPassword;
