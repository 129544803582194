import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import '../assets/styles/Navbar.css';
import logoImage from '../assets/images/logo.png';

const Navbar = () => {
  const { isAuthenticated } = useContext(AuthContext);

  return (
    <nav className="navbar">
      <div className="navbar-container">
        {/*logo - if is authenticated, redirect to /services, otherwise redirect to / */}
        {isAuthenticated ? (
          <Link to="/services" className="navbar-logo">
            <img src={logoImage} alt="PROA Tech Logo" className="navbar-logo-image" />
          </Link>
        ) : (
          <Link to="/" className="navbar-logo">
            <img src={logoImage} alt="PROA Tech Logo" className="navbar-logo-image" />
          </Link>
        )}

        {isAuthenticated && (
          <ul className="nav-menu">
            {/* Navigation items for authenticated users */}
            <li className="nav-item">
              <Link to="/changelog" className="nav-links">
                Aktualizacje
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/services" className="nav-links">
                Usługi
              </Link>
            </li>
            {/* <li className="nav-item">
              <Link to="/settings" className="nav-links">
                Ustawienia
              </Link>
            </li> */}
            <li className="nav-item">
              <Link to="/logout" className="nav-links">
                Wyloguj
              </Link>
            </li>
          </ul>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
