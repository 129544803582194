import React from 'react';
import { Link } from 'react-router-dom';
import '../assets/styles/Start.css';

const Start = () => {
  return (
    <div className="start-container">
      <h1>Witaj w portalu PROA Technology!</h1>
      <p>Z łatwością zarządzaj swoimi usługami i botami.</p>
      <div className="start-buttons">
        <Link to="/login" className="btn btn-primary">Logowanie</Link>
        <Link to="/register" className="btn btn-secondary">Rejestracja</Link>
      </div>
    </div>
  );
};

export default Start;
