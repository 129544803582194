import React, { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from './AuthContext';

const Logout = () => {
  const navigate = useNavigate();
  const { setIsAuthenticated } = useContext(AuthContext);
  useEffect(() => {

    // Clear user token and other relevant data
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    setIsAuthenticated(false);
    // If you're using a global state management solution like Redux, you would also dispatch an action to clear the user state

    // Redirect to login page or home page
    navigate('/');
  }, [navigate, setIsAuthenticated]);

  // Optionally, you can render a message or a spinner while the logout process completes
  return (
    <div>
      Logging out...
    </div>
  );
};

export default Logout;
